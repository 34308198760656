import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import GraphicDesignPage from "../../components/services/graphic-design/index"
const graphicdesignPage = () => {
  return (
    <Layout>
      <Seo title="Services - Graphic Design" desc="" pathname="" keywords="" />
      <GraphicDesignPage />
    </Layout>
  )
}

export default graphicdesignPage

import React from "react"

const graphicexclusive = () => {
  return (
    <section className="exclusive">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-12 text-center">
            <p className="services-digital">
              Exquisite graphic designs with refreshing ideas
            </p>
            <p className="story-text">
              Mass Media Creatives has a team rich in creativity and imagination
              that is streamlined with technology and backed by our brilliant
              graphic designers driven towards achieving the graphic design
              objectives of all the clients. Mass Media Creatives has a team
              rich in creativity and imagination that is streamlined with
              technology and backed by our brilliant graphic designers driven
              towards achieving the graphic design objectives of all the
              clients.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default graphicexclusive

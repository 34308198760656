import React from "react"
import Slider from "./slider"
import Gallery from "./gallery"
import Graphic from "./graphic"
import Exclusive from "./exclusive"
import Services from "./services"

import Portfolio from "../../portfolio/portfolio"
import Service from "../../home/services"
import Clients from "../../home/clients"


const graphicdesignhome = () => {
  return (
    <>
      <Slider />

      <Graphic />
      <Gallery />
      <Exclusive />
      <Services />
 
      <Portfolio />
      <Service />
      <Clients />
    </>
  )
}

export default graphicdesignhome

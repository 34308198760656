import React from "react"

import gallery from "../../../images/showcase/Image 36.png"

const graphicgallery = () => {
  return (
    <>
      <section className="exclusive">
        <div className="helped">
          <img src={gallery} alt="gallery" />

          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="headprds position-relative">
                  <h4 className="text-start">Graphic Design</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p className="graphic-para">Graphic Design</p>
      </section>
    </>
  )
}

export default graphicgallery

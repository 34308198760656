import React from "react"
import { Link } from "gatsby"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons"
import box from "../../../images/svg/menu.svg"
import Right from "../../../images/svg/left-arrow.svg"
import Left from "../../../images/svg/right-arrows.svg"


const graphicservices = () => {
  return (
    <>
      <section className="exclusive">
        <div className="container">
          <div className="row ">
            <div className="col-12 col-lg-4">
              <h1 className="page-title exclusive-graphic">Graphic Services</h1>
              <ul className="service-graphic">
                <li>Logo Design </li>
                <li>Business Card / Stationery</li>
                <li>Brochure / Catalogue / Book</li>
                <li>Leaflet / Pamphlet / Flyer / Poster</li>
                <li>Label / Package / Sticker</li>
                <li>Hoarding / Banner / Sign Board</li>
                <li>Invitation / Greeting Card</li>
                <li>Magazine / Newspaper AdS</li>
                <li>Backdrop / Stage / stall</li>
                <li>Wobbler / Dangler / Bunting</li>
                <li>Menu Card</li>
                <li>Calendar</li>
                <li>Standee </li>
                <li>Canopy</li>
              </ul>
            </div>
            <div className="col-12 col-lg-2 d-none d-lg-block"></div>
            <div className="col-12 col-lg-4 d-none d-lg-block">
              <h1 className="page-title exclusive-graphic">Web Services</h1>
              <ul className="service-graphic">
                <li>PPT Presentation</li>
                <li>Emailer </li>
                <li>Website Banner</li>
                <li>Info Graphic</li>
                <li>Newsletter </li>
                <li>Coupon / Gift Voucher</li>
                <li>Kiosk </li>
                <li>Ad Campaign</li>
              </ul>
            </div>
            <div className="col-12 col-lg-12 text-center page-next py-4">
              <div className="d-flex align-items-center justify-content-center">
              <Link to="/services/printing-services">
                {" "}
                <img src={Right} alt="" className="navigation-left"></img>{" "}
                <span>
                Printing Services
                </span>
             
              </Link>
              <img src={box} className="navigation-btn" alt="" ></img>
              <Link to="/services/photography-videography">
                {" "}
                <span>
                Photography &amp; Videography
                </span>
               
                <img src={Left} alt="" className="navigation-right"></img>     
              </Link>
              </div>
             
            </div>

         
          </div>
        </div>
      </section>
    </>
  )
}

export default graphicservices
